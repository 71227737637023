<template>
  <div class="main">
    <div class="header">
      <div v-if="headType==1" class="header_box1">
        <van-swipe class="my-swipe" indicator-color="#ee2e2e" :initial-swipe="swipeIndex" @change="onChange">
          <van-swipe-item>
            <img src="https://newoss.zhulong.com/forum/202405/16/17/1715852352148298.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img src="https://newoss.zhulong.com/forum/202405/16/17/1715852352148298.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img src="https://newoss.zhulong.com/forum/202405/16/17/1715852352148298.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img src="https://newoss.zhulong.com/forum/202405/16/17/1715852352148298.jpg" alt="">
          </van-swipe-item>
        </van-swipe>
        <div class="btn" @click="sq">
          <span>收起</span>
          <van-icon name="arrow-up" />
        </div>
      </div>
      <div v-else class="header_box2">
        <div class="back"></div>
        <img src="https://newoss.zhulong.com/forum/202405/16/17/1715852645910519.jpg" alt="">
        <div class="btn" @click="zk">
          <span>展开</span>
          <van-icon name="arrow-down" />
        </div>
      </div>
    </div>
    <div class="content" :class="headType==1?'paddingheight1':'paddingheight2'">
      <div v-for="(item,index) in list" :key="index">
        <div :class="item.type == 1 ? 'left' : 'right'">
          <img src="https://newoss.zhulong.com/forum/202405/16/17/1715852645910519.jpg" alt="">
          <div class="text">
            <div class="text1">{{ item.en }}</div>
            <div class="text2">{{ item.cn }}</div>
            <!-- <audioplay :record-file="item.wav" :currentAudioIndex="index"></audioplay> -->
            <div class="sound" @click="autoPlay(item,index)">
              <img v-if="!item.isPlay" src="https://newoss.zhulong.com/forum/202405/15/11/1715745493590909.png" alt="">
              <img v-else src="https://newoss.zhulong.com/forum/202405/15/12/1715746097385106.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <van-loading v-if="loading" class="pageloading" />
    </div>
    <div class="bottom">
      <div v-if="!speakIng" class="btn" :style="{ pointerEvents: !speakIng ? 'auto' : 'none' }" @touchstart="!loading&&startRecordAudio()">
        按下说话
      </div>
      <div v-else class="btn2">
        <div class="clear" :style="{ pointerEvents: speakIng ? 'auto' : 'none' }" @touchstart="stopRecordAudio">
          <img src="https://newoss.zhulong.com/forum/202405/15/14/1715755888206079.png" alt="">
        </div>
        <div class="">
          <div class="timing">{{ recorder.duration.toFixed(2) }}</div>
          <div class="loading">
            <div class="load"></div>
            <div class="load"></div>
            <div class="load"></div>
            <div class="load"></div>
          </div>
        </div>
        <div class="send" @click="send">
          <img src="https://newoss.zhulong.com/forum/202405/15/14/1715756134139292.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Recorder from 'js-audio-recorder'
import audioplay from './audio'
export default {
  components: {
    audioplay
  },
  data() {
    return {
      loading: false,
      headType: 1,
      swipeIndex: '',
      swipeIndex2: '',
      recorder: new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1 // 声道，支持 1 或 2， 默认是1
        // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
      }),
      speakIng: false,
      list: [],
      myAuto: new Audio(),
      myAutoIndex: ''
    }
  },
  methods: {
    // 轮播图监听事件
    onChange(index) {
      console.log(index, 'index')
      this.swipeIndex2 = index
    },
    getMicrophoneAccess() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            // 获取麦克风成功，可以在这里处理流，例如播放等
            console.log('麦克风访问成功')
            // // 播放音频
            // const audio = new Audio();
            // audio.srcObject = stream;
            // audio.play();
          })
          .catch(error => {
            console.error('麦克风访问失败：', error)
          })
      } else {
        alert('您的浏览器不支持获取麦克风')
      }
    },
    // // 播放
    // playSound(item) {
    //   this.list.forEach(res => {
    //     res.isPlay = false
    //   })
    //   item.isPlay = !item.isPlay
    // },
    // // 暂停
    // stopSound(item) {
    //   console.log(item,'item')
    //   item.isPlay = !item.isPlay
    // },
    // 开始录音
    startRecordAudio() {
      this.getMicrophoneAccess()
      Recorder.getPermission().then(
        () => {
          console.log('开始录音')
          this.recorder.start() // 开始录音
          this.speakIng = true
        },
        (error) => {
          this.$message({
            message: '请先允许该网页使用麦克风',
            type: 'info'
          })
          console.log(`${error.name} : ${error.message}`)
        }
      )
    },
    // 停止录音
    stopRecordAudio() {
      this.recorder.stop() // 停止录音
      this.speakIng = false
    },
    // 播放MP3
    autoPlay(item, index) {
      if (item.wav) {
        if ((this.myAutoIndex === 0 || this.myAutoIndex > 0) && this.myAutoIndex != index) {
          console.log('zhixingle?')
          this.list[this.myAutoIndex].isPlay = false
        }
        this.myAutoIndex = index
        this.myAuto.src = item.wav
        console.log(item.isPlay, 'item.isPlay')
        item.isPlay = !item.isPlay
        if (item.isPlay) {
          this.myAuto.play()
          this.palyEnd(item)
        } else {
          this.myAuto.pause()
          this.palyEnd(item)
        }
      } else {
        this.$toast('没找到播放源')
      }
    },
    palyEnd(item) {
      this.myAuto.addEventListener('ended', () => {
        item.isPlay = false
      })
    },
    send() {
      this.recorder.stop() // 停止录音
      this.speakIng = false
      this.loading = true
      this.$nextTick(() => {
        this.scorellToBottom()
      })
      var timeed = Number(this.recorder.duration.toFixed(2))
      if (timeed > 3) {
        var pcmBlob = this.recorder.getPCMBlob()
        var formData = new FormData()
        // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
        const newbolb = new Blob([pcmBlob], { type: 'audio/pcm' })
        // 获取当时时间戳作为文件名
        const fileOfBlob = new File([newbolb], new Date().getTime() + '.pcm')
        formData.append('audioFile', fileOfBlob)
        const axios = require('axios')
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        // 发送请求
        axios.post('https://m.zhulong.com/ucenter/prod-api/api/ai/aiEnglish', formData, config)
          .then(response => {
            console.log(response.data)
            var res = response.data
            if (res.errNo == 0) {
              this.list.push(
                {
                  type: 2,
                  isPlay: false,
                  cn: res.result.message.q_cn,
                  en: res.result.message.q_en,
                  wav: res.result.q_wav
                },
                {
                  type: 1,
                  isPlay: false,
                  cn: res.result.message.a_cn,
                  en: res.result.message.a_en,
                  wav: res.result.wav
                }
              )
              this.loading = false
              // 播放MP3
              this.autoPlay(this.list[this.list.length - 1], this.list.length - 1)
              this.$nextTick(() => {
                this.scorellToBottom()
              })
            }
          })
          .catch(error => {
            console.error(error)
            this.$toast('抱歉，请重新说一下')
            this.loading = false
          })
      } else {
        this.loading = false
        this.$toast('录音时间不得少于3s')
      }
    },
    scorellToBottom() {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
    },
    // 头部收起
    sq() {
      this.headType = 2
    },
    // 头部展开
    zk() {
      this.swipeIndex = JSON.parse(JSON.stringify(this.swipeIndex2))
      this.headType = 1
    }
  }
}
</script>
<style lang="scss" scoped>
*{
  margin: 0;
  padding: 0;
}
.main{
  background: #F8F9FD;
  min-height: calc(100vh - 220px);
}
.header{
  width: 100%;
  position: fixed;
  z-index: 1;
  .header_box1{
    height: 476px;
    img{
      width: 100%;
    }
    .btn{
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0 40px 40px 0;
      font-size: 28px;
      span{
        margin-right: 6px;
      }
    }
  }
  .header_box2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    background: url('https://newoss.zhulong.com/forum/202405/16/18/1715854783222884.jpg') round;
    padding: 0 20px;
    .back{
      flex-shrink: 0;
      width: 88px;
      height: 38px;
    }
    img{
      width: 80px;
      border-radius: 100%;
    }
    .btn{
      font-size: 28px;
      span{
        margin-right: 6px;
      }
    }
  }
}
.paddingheight1{
  padding: 520px 40px 220px;
}
.paddingheight2{
  padding: 140px 40px 220px;
}
.content{
  .pageloading{
    text-align: center;
  }
  .left,.right{
    display: flex;
    margin-bottom: 40px;
    position: relative;
    >img{
      width: 80px;
      height: 80px;
      border-radius: 100%;
    }
    .text{
      max-width: calc(100% - 260px);
      padding: 30px 30px 30px;
      line-height: 46px;
      border-radius: 20px;
      .text1{
        font-size: 32px;
        color: #333;
      }
      .text2{
        font-size: 26px;
        color: #666;
      }
    }
    .sound{
      float: right;
      width: 80px;
      height: 40px;
      border: 1px solid #666;
      border-radius: 20px;
      line-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 32px;
        height: 32px;
      }
    }
  }
  .left{
    >img{
      margin-right: 20px;
    }
    .text{
      background: #fff;
    }
    .text::after {
      position: absolute;
      top: 20px;
      left: 76px;
      content: '';
      border-width: 14px;
      border-style: solid;
      border-top-color: transparent;
      border-right-color: #fff;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
  }
  .right{
    flex-flow: row-reverse;
    >img{
      margin-left: 20px;
    }
    .text{
      background: #FFCFCF;
    }
    .text::after {
      position: absolute;
      top: 20px;
      right: 76px;
      content: '';
      border-width: 14px;
      border-style: solid;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: #FFCFCF;
    }
  }
}

.bottom{
  width: calc(100% - 80px);
  position: fixed;
  bottom: 0;
  background: #F8F9FD;
  padding: 50px 40px 100px;
  .btn{
    height: 70px;
    margin: 0 auto;
    border-radius: 32px;
    background: #D71616;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 70px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
  .btn2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .clear{
      background: #ffffff;
      width: 110px;
      height: 110px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 40px;
      }
    }
    .send{
      background: #ee2e2e;
      width: 110px;
      height: 110px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 50px;
      }
    }
    .timing{
      text-align: center;
      font-size: 32px;
      margin-bottom: 10px;
    }
    .loading {
      display: flex;
      align-items: center;
      height: 66px;
      justify-content: center;
    }
    .load {
      width: 10px;
      background-color: #EE2D31;
      animation: 1s move6 infinite;
      border-radius: 10px;
      margin: 5px;
    }
    .load:nth-child(1) {
      animation-delay: 0.2s;
    }
    .load:nth-child(2) {
      animation-delay: 0.4s;
    }
    .load:nth-child(3) {
      animation-delay: 0.6s;
    }
    @keyframes move6 {
      0% {
        height: 22px;
      }

      25% {
        height: 44px;
      }

      50% {
        height: 66px;
      }

      100% {
        height: 22px;
      }
    }
  }
}
</style>
